<template>
    <v-container grid-list-xl fluid>
        <h1>Relatório de Carregamentos</h1>
        <v-divider class="mb-4" />
        <v-layout wrap>
            <v-flex xs12 md4>
                <DateInterval @onChange="change" />
            </v-flex>
            <v-flex xs12 md4>
                <v-autocomplete v-model="selectedCharger" :items="charges" item-text="name" item-value="name" label="Filtrar por carregador" return-object="true"
                    :disabled="$store.getters.isLoading || charges.length <= 1" :loading="$store.getters.isLoading" clearable />
            </v-flex>

            <v-flex xs12 md4 text-center class="d-print-none">
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success" style="width: 100%">Download em CSV<v-icon right dark> download </v-icon></v-btn>
                </DownloadCsv>
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>battery_charging_full</v-icon>
                            {{ total }} kWh
                        </div>
                        Energia consumida
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>score</v-icon>
                            {{ listWithFilter.length }}
                        </div>
                        Número de carregamentos
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 v-if="paymentValue > 0">
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>paid</v-icon>
                            R$ {{ paymentValue }}
                        </div>
                        Pagamentos
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm12 v-if="items.length == 0">
                <v-alert type="error"> Nenhum dado encontrado para esse perído de tempo. </v-alert>
            </v-flex>
            <v-flex xs12 v-if="items.length > 0">
                <v-data-table :headers="headers" :items="listWithFilter" :items-per-page="-1" class="elevation-2" hide-default-footer :loading="!items">
                    <v-progress-linear v-slot:progress color="blue" indeterminate />
                    <template v-slot:[`item.car.consumption`]="{ item }">{{ item.car.consumption }} kWh</template>
                    <template v-slot:[`item.car.minutesCharger`]="{ item }">{{ dateTime.showMinutes(item.car.minutesCharger) }}</template>
                    <template v-slot:[`item.paymentValue`]="{ item }">
                        <ChipPaymentValue :paymentValue="item.paymentValue" :payment="item.payment" />
                    </template>
                    <!-- <template v-slot:item.paymentValue="{ item }">
                        <v-chip v-if="item.paymentValue > 0" class="ma-2" color="success" text-color="white">
                            <v-avatar left> <v-icon>done</v-icon> </v-avatar>Pago R${{item.paymentValue.toFixed(2)}}
                        </v-chip>
                        <v-chip v-else-if="item.paymentValue == 0" class="ma-2" color="grey" text-color="white">
                            <v-avatar left> <v-icon>grade</v-icon> </v-avatar>Grátis
                        </v-chip>
                        <v-chip v-else class="ma-2" color="error" text-color="white">
                            <v-avatar left> <v-icon>error</v-icon> </v-avatar>Pendente
                        </v-chip>
                    </template> -->
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";

import dateTime from "@/helpers/DateTime";
import DateInterval from "@/components/core/DateInterval";
import ChipPaymentValue from "@/components/shared/Payment/ChipPaymentValue";

export default {
    name: "ClientReport",
    components: {
        DateInterval,
        DownloadCsv,
        ChipPaymentValue
    },
    data() {
        return {
            dateTime,
            selectedCharger: null,
            charges: [],
            headers: [
                { text: "Data", value: "dateTime", align: "center" },
                { text: "Tempo Carregando", value: "car.minutesCharger", align: "center" },
                { text: "Consumo", value: "car.consumption", align: "center" },
                { text: "Status Pagamento", value: "paymentValue", align: "center" },
                // { text: "Status Pagamento", value: "paymentValue", align: "center" },
                { text: "Carregador", align: "left", value: "charger.name", visibility: false },
            ],
            items: [],
            csvLabels: {
                dateTime: "Data",
                minutesCharger: "Tempo Carregando(minutos)",
                consumption: "Consumo(kWh)",
                name: "Carregador",
            },
        };
    },
    methods: {
        change(dates) {
            let query = dates.length == 2 ? `?dateFrom=${dates[0]}&dateTo=${dates[1]}` : `?dateFrom=${dates[0]}&dateTo=${dates[0]}`;

            this.$http
                .get("api/v2/car/charge/" + this.$store.state.user.ID + query)
                .then((result) => {
                    this.selectedCharger = null;
                    this.charges = [];
                    result.forEach((item) => {
                        if (!this.charges.includes(item.charger.name)) {
                            this.charges.push(item.charger.name);
                        }
                    });
                    this.items = result;
                })
                .catch(() => {
                    this.charges = [];
                    this.items = [];
                });
        },
    },
    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        dateTime: i.dateTime,
                        minutesCharger: i.car.minutesCharger,
                        consumption: i.car.consumption,
                        name: i.charger.name,
                    };
                });
            }

            return null;
        },
        listWithFilter() {
            if (this.selectedCharger) {
                return this.items.filter((item) => item.charger.name == this.selectedCharger);
            } else {
                return this.items;
            }
        },
        total() {
            return Number.parseFloat(
                this.listWithFilter.reduce(function (sum, i) {
                    return sum + i.car.consumption;
                }, 0)
            ).toFixed(2);
        },
        paymentValue() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return sum + i.paymentValue;
                }, 0)
            ).toFixed(2);
        },
    },
};
</script>